.EditorComponent {
    font-family: sans-serif;
    overflow-y: hidden;
  }
  
  .fr-toolbar .fr-btn-grp {
    margin: 0 !important;
  }
  .fr-view .fr-img-caption .fr-img-wrap>span {
    display: inherit !important;
  }
  .grayTableBorder,
  .grayTableBorder tr th,
  .grayTableBorder tr td {
    border: 1px solid lightgray !important;
    border-collapse: collapse;
  }
  
  .blackTableBorder,
  .blackTableBorder tr th,
  .blackTableBorder tr td {
    border: 1px solid black !important;
    border-collapse: collapse;
  }
  .fr-view table td{
    height: auto !important;
    padding: 0 !important;
  }
  .noTableBorder,
  .noTableBorder tr th,
  .noTableBorder tr td {
    border: none !important;
    border-collapse: collapse;
  }
  