html {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(180deg, #f1f6fb 43.23%, #fbf8ee 100%); */
}

#menu {
  height: 52px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #034E91;
}

.header-logo-img {
  height: 32px;
}

.menu-icon-btn {
  box-shadow: none !important;
  border: none !important;
  /* padding: 13px 13px 13px 0px; */
  display: flex;
  /* gap: 25px; */
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.menu .menu-icon-btn.item>i.icon {
  margin-right: 10px;
}

.imageWrap {
  height: 50px;
  width: 50px;
}

.content-container {
  margin-left: 2%;

}
.search-dropdown{
  display: flex;
  position: absolute;
  right: 33px;
  top: 210px;
  /* margin-bottom: 40px; */
  justify-content: flex-end;
  margin-right: 7rem;
}
.table-header {
  text-align: center !important;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-content: center;
}

.tableWrap table{

border: none;
}

.tableWrap td{
  border: none;
}
.tableWrap th{
  border: none;
}
.main-header{
  display: flex;
  gap: 20px;
  cursor: pointer;
  
}

.main-header button{
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
}
.main-header button:active{
  border-bottom: 2px solid black;
    opacity: 1;
}

.content-container 
.page-header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}

.section-header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #333333;
}

.section-body-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.search-input-box {
  width: 100%;
}

.thankyou-outer-container {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.thankyou-inner-container {
  text-align: center;
}

.thankyou-btn {
  width: 90%;
  position: absolute;
  bottom: 3%;
  height: 40px;
  left: 6%;
}

.thankyou-btn.troubleshoot-btn {
  bottom: 10%;
}

.ui.blue.button,
.ui.blue.buttons {
  background-color: #0971ce !important;
}
.ui.button {
  opacity: inherit;
}

.ui.button,
.ui.button:hover,
.ui.button:focus {
  border: 1px solid #cbcbcc00;
  color: #0971ce;
  background-color: white;
}

/* Troubleshooting Action page */
.ts-action-btn {
  width: 150px;
  height: 40px;
}

.ts-action-container {
  margin-bottom: 55px;
}

.icon-text-wrapper .tech-icon {
  width: 32px;
  height: 32px;
  float: left;
  margin-top: 6px;
}

.icon-text-wrapper .page-header-text {
  float: right;
  width: calc(100% - 35px);
  padding: 5px 0px 0px 10px;
}

.icon-text-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 16px 0px;
}

.marginL3 {
  margin-left: 3em;
}

/* Reference */
.para-ref-btn {
  border-radius: 17px;
  background-color: #e6f1fa;
  color: #0971ce;
  padding: 5px;
}

.para-ref-btn:hover {
  background-color: #0971ce;
  color: white;
}

/* Procedure page */
.procedure-title-text {
  padding: 15px !important;
}

.procedure-container {
  padding: 5px !important;
}

.procedure-accordion.ui.header {
  font-size: 18px;
  margin-bottom: 15px;
}

/* Sidbar */
.ui.icon.menu .item {
  line-height: 20px;
  text-align: justify;
  font-weight: 600;
  margin-top: 5px;
}


.ui.vertical.menu .item::before {
  height: 0px;
}
/* Not fully complete on the colouring of options in sidemenu */
.ui.vertical.menu .item:active{
  background-color: #034E91;;
  }
  

.ui.labeled.icon.menu .item>.icon:not(.dropdown) {
  float: left;
  margin-right: 5px !important;
}

.ui.thin.left.sidebar,
.ui.thin.right.sidebar {
  width: 65%;
  position: fixed;
}

/* Troubleshooting action & procedure Cards */
.content-container-card {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 5px;
}

.card-margin {
  margin-bottom: 7rem !important;
}
/* Troubleshooting option card */
.option-card-tag {
  background-color: #ffa500;
  color: #ffffff;
  width: 2em;
  height: 2em;
  text-align: center;
  border-radius: 50%;
  padding: 0.3em;
}

.option-card-btn {
  text-align: center;
  background: #f2f2f2 !important;
  color: #0971ce;
  font-weight: 600;
  border: none !important;
  box-shadow: 0em 0.2em 0em #d1d2ce !important;
}

.option-card {
  box-shadow: none !important;
}

/*Breadcrumbs*/
.backbutton-div {
  width: 16%;
  padding: 0.66em 0.5em;
  text-align: center;
  float: left;
  color: #0971ce;
  font-size: 2em;
  font-weight: 1000;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.container-div {
  height: 4em;
  background: #fff;
  position: relative;
  z-index: 10;
}

.ui.breadcrumb.breadcrumbs-div {
  overflow-x: auto;
  overflow-y: hidden;
  float: right;
  width: 84%;
  padding: 1.6em 1.1em 1.1em 1.1em;
  white-space: nowrap;
}

.ui.breadcrumb.breadcrumbs-div a {
  color: #828282;
}

/* Thank you Page */
.ui.container.thankyou-inner-container {
  display: flex;
  align-items: center;
  height: 55vh;
  width: 100% !important;
  margin-left: 0 !important;
}

.thankyou-inner-container img {
  margin: 0 auto 2em auto;
}

.thankyou-inner-container .section-body-text {
  width: 82%;
}

.marginL2 {
  margin-left: 2em;
}

.prod-logo {
  text-align: center;
  width: 10rem;
  cursor:pointer;
}

.prod-logo .ui.header {
  font-size: 0.8rem;
  margin: 0;
  color: #4a4a4a;
}

.schematic-container .react-transform-component .react-transform-element,
.schematic-container .react-transform-component .react-transform-element img {
  display: inherit;
}

.energyLogo-Wrapper {
  background: #fff;
  width: 100%;
  height: 70px;
  bottom: 0%;
}

.energyLogo-Wrapper img {
  width: 75px;
  margin: 0 auto;
  height: 40px;
  display: inline-block;
  padding-top: 12px;
}

.ui.segment.loader-segment {
  height: 100vh;
  margin: 0px;
}

.edit-user-profile-container {
  margin: 60px 15px 30px 15px;
}

.edit-user-profile-container .image-wrapper {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  position: relative;
}

.edit-user-profile-container .image-wrapper label.ui.icon.button>.icon {
  display: block;
  margin: 10px auto !important;
}

.ui.attached.button.available-btn {
  width: 50%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 13px;
  border: 1px solid #bdbdbd;
  text-align: center;
  color: #828282;
}

.ui.attached.button.busy-btn {
  width: 50%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 13px;
  border: 1px solid #bdbdbd;
  text-align: center;
  color: #828282;
}

.ui.attached.button.active {
  border-color: #27ae60;
  background: #e3f8eb;
  color: #27ae60;
}

.ui.attached.button.busy-btn.active {
  border-color: #db615c;
  background: #eaadab;
  color: #db615c;
}

.edit-icon-profile {
  float: right;
  margin-right: 20px;
  color: #0971ce;
}

.edit-icon-profile:hover {
  cursor: pointer;
}

.edit-icon-profile-container {
  overflow: hidden;
  clear: both;
}

.edit-user-profile-container .image-wrapper label {
  width: 140px;
  height: 140px;
  border-radius: 90px;
  text-align: center;
  padding: 55px 0px;
  border: 1px dashed #e0e0e0;
  background: #fff;
}

.edit-user-profile-container .image-wrapper img {
  position: absolute;
  top: 0px;
  width: 140px;
  height: 140px;
  border-radius: 90px;
  left: 0px;
}

.user-profile-btn.messageCall-container {
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  height: 65px;
}

.messageCall-container div {
  position: fixed;
  bottom: 0;
  padding: 10px;
  width: 100%;
}

.messageCall-container button:nth-child(1),
.messageCall-container button:nth-child(2) {
  border: 1px solid #0971ce;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 14px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0971ce;
  display: inline-block;
  width: 98%;
}

.messageCall-container button:nth-child(2) {
  width: 26%;
}

.user-profile-btn.messageCall-container button.ui.button:nth-child(3) {
  width: 54%;
  background: #0971ce;
  color: #fff;
}

.error.profile {
  background: #fff;
  color: red;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.teamList .ui.feed>.event>.label img {
  width: 24px;
  height: 24px;
}

.teamList .ui.feed .content .date {
  display: inline-block;
  margin-left: 1rem;
  font-weight: normal;
  line-height: 18px;
  font-size: 12px;
  color: #828282;
}

.teamList .ui.feed .content .summary {
  font-weight: normal;
  line-height: 18px;
  color: #4f4f4f;
  margin-top: 5px;
}

.teamList .ui.feed>.event>.label+.content {
  margin: -5px 0.35em 2em 0.1em;
}

.teamList .ui.feed .content .name {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  display: inline-block;
}

.teamList .bottom-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px;
  border-top: 1px solid #e0e0e0;
}

.teamList .bottom-nav input {
  background: #f2f2f2;
  border-radius: 4px;
  border: none;
  padding: 15px;
  width: 85%;
}

.chat-container {
  height: 82vh;
}

.ui.modal .teamList.scrolling.content {
  max-height: inherit;
}

.ui.modal .teamUpdateContainer.teamList.scrolling.content {
  max-height: 85vh;
}

i.icon.marL10 {
  margin-left: 10px;
}

.ui.menu.session-bar {
  position: fixed;
  bottom: 0%;
  left: 0%;
  height: 9vh;
  background-color: white;
  margin: 0 !important;
  z-index: 101;
  width: 100% !important;
}

.ui.menu.session-bar .footer-menu.item {
  width: calc(100% / 4);
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
}

.ui.menu.session-bar .footer-menu.shared.item {
  width: calc(50%);
}

.ui.menu.session-bar .footer-menu img {
  display: inline;
}

/* .ui.menu.session-bar .footer-menu img:hover {
  filter: invert(8%) sepia(100%) saturate(7447%) hue-rotate(248deg) brightness(96%) contrast(143%);
} */

.svgClass {
  height: 24px;
  width: 24px;
  fill:#000000;
  fill: rgb(128,128,128);
  background: transparent;
}

.svgClass:hover {
  filter: invert(43%) sepia(92%) saturate(5969%) hue-rotate(207deg) brightness(102%) contrast(107%);
}


.ui.menu.session-bar .footer-menu.item p {
  font-size: 9px;
  margin: 0.5em 0em 0em 0em;
  font-family: "Inter", sans-serif;
  color: #828282;
  line-height: 7px;
}

.ui.menu.session-bar .footer-menu.item .dropdown {
  font-size: 9px;
  font-family: "Inter", sans-serif;
  color: #828282;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.ui.menu.session-bar .footer-menu.item .dropdown>.text {
  text-align: center;
  display: block;
  position: absolute;
  top: 44px;
  /* left: 40%; */
}

.ui.menu.session-bar .footer-menu.item .dropdown i.dropdown.icon {
  display: none;
}

.ui.menu.session-bar .footer-menu.item .dropdown .menu.transition {
  padding: 8px;
  font-size: 13px;
  right: 0;
  left: -64px;
  bottom: 62px;
}

.ui.menu.session-bar .footer-menu.item .dropdown.active,
.ui.menu.session-bar .footer-menu.item .dropdown:hover {
  background: none;
}

.ui.menu.session-bar .footer-menu.item .dropdown .menu.transition img {
  width: 15px;
  height: 8px;
  margin-top: 3px;
}

.ui.modal.addMember-container .teamList.scrolling.content {
  padding: 0 !important;
}

.ui.modal.addMember-container .teamList .event {
  padding: 1rem 1rem 0 1rem;
}

.ui.modal.addMember-container .teamList .event.blue {
  background: #eef5fc;
}

.my-sessions,
.task-overview,
.qrcode-scanner {
  padding: 65px 20px 10px 20px;
  background-color: #f8f8f8;
  min-height: 100vh;
}

.my-sessions .searchTeam {
  padding: 0 0px 20px 0px;
}

.my-sessions h2.ui.header {
  padding: 20px 0px 10px 0;
}

.my-sessions .each-session {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
}

.my-sessions .each-session .time {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #b9c3c5;
}

.my-sessions .each-session .query {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c2122;
}

.my-sessions .each-session .task-name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6c7779;
  float: left;
  text-transform: capitalize;
}

.notification-number.sessionsCount {
  position: inherit;
  float: right;
  top: inherit;
  left: inherit;
}

.notification-number.allSession {
  position: initial;
  margin-left: 4px;
  display: inline-block;
  vertical-align: super;
}

.my-sessions .each-session .info {
  position: relative;
}

.my-sessions .each-session .task-status {
  border-radius: 2px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  float: right;
  padding: 0px 5px;
}

.my-sessions .each-session .task-status.yellow {
  background: #f2c94c;
}

.my-sessions .each-session .task-status.green {
  background: #27ae60;
}

.my-sessions .shared-me {
  float: right;
  font-size: 12px;
  text-align: center;
  color: #4f4f4f;
  background: #f2f2f2;
  border-radius: 17px;
  padding: 2px 5px;
}

.my-sessions .each-session .task-details {
  overflow: hidden;
  clear: both;
  margin: 10px 0;
}

.my-sessions .each-session .people-icon {
  margin-top: 10px;
  width: 15px;
  height: 15px;
}

.search-results-container {
  margin-bottom: 6em;
}

.notification-number {
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: #eb5757;
  border-radius: 20px;
  color: #fff;
  padding: 3px;
  position: absolute;
  top: 10px;
  left: 85px;
  cursor:pointer;
}

.teamList.sessionList.content .list>.item>.image+.content {
  width: 38%;
}

.ui.modal .content>.image.sessionInvite {
  display: inline-block;
  margin-top: 15px !important;
}

.each-session .ui.circular.label.collaborators {
  width: 15px;
  height: 15px;
  padding: 0 !important;
}

.each-session .ui.circular.label.collaborators a {
  border-radius: 12px;
}

.my-sessions .each-session a:nth-of-type(n + 2) {
  margin-left: -7px;
}

.teamList.chatWrapper .bottom-nav {
  padding: 10px 5px;
}

.teamList.chatWrapper .bottom-nav div.ui.input {
  width: 68%;
  margin-left: 10px;
}

.teamList.chatWrapper .bottom-nav input {
  padding: 12px;
}

.justifyEnd .backgroundBlue {
  background: linear-gradient(96.33deg, #0f69b9 5.42%, #4c9de6 97.79%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.justifyEnd .left-align {
  float: left;
  margin-right: 10px;
  width: calc(100% - 40px);
}

.justifyEnd .chatIcon {
  float: right;
}

.justifyEnd .messageLeft {
  border-radius: 8px 2px 8px 8px;
  padding: 10px;
  float: right;
}

.justifyEnd .date,
.justifyStart .date {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}

.justifyEnd {
  float: right;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 10px;
  clear: both;
  margin-bottom: 15px;
}

.justifyEnd img,
.justifyStart img {
  float: left;
}

.justifyStart .backgroundLight {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 10%), 0px 2px 8px rgb(0 0 0 / 5%);
}

.justifyStart .right-align {
  border-radius: 2px 8px 8px 8px;
  padding: 10px;
  float: right;
}

.justifyStart .messageWhite {
  float: right;
  margin-left: 10px;
  width: calc(100% - 40px);
}

.justifyStart {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  clear: both;
  float: left;
}

.justifyStart .chatIcon {
  float: left;
}

.chatIcon {
  width: 30px;
  height: 30px;
  border-radius: 17px;
}

.online-person {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.nameChat {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #828282;
}

.online-header {
  overflow: hidden;
}

.online-header img {
  float: left;
}

.online-header img:nth-of-type(n + 2) {
  margin-left: -10px;
}

.ui.button.mediaInput {
  float: left;
  padding: 0px;
  background: transparent;
  margin-top: 5px;
  border: none;
  margin-left: 10px;
  width: auto;
  outline: none;
  -webkit-appearance: inherit;
}

.chat-container .actions .field {
  overflow: hidden;
  padding-bottom: 10px;
}

.chat-container .actions .field div.ui.input {
  width: calc(100% - 100px);
}

.sharedImg {
  width: 100px;
}

.sessionList.chatWrapper {
  height: calc(83vh - 10em);
  overflow: auto;
}

.messageWrapper {
  width: 100%;
  overflow: hidden;
}

.task-type {
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #828282;
  background: #f2f2f2;
  border-radius: 2px;
  padding: 2px 5px;
  margin: 0px 5px;
}

.task-priority {
  background: #f2994a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 3px 2px 6px;
  margin: 0px 5px;
  float: inherit;
}

.task-priority.mel {
  background: #f2994a;
}

.task-priority.aog {
  background: #eb5757;
}

.task-priority.safety {
  background: #27ae60;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.status-avatar {
  margin: 10px 5px;
}

.status-avatar .status {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-left: 2px;
}

.status-avatar .update {
  border-radius: 10px;
  width: 8px;
  display: inline-block;
  height: 8px;
}

.status-avatar .paused {
  background: #f8ba00;
}

.status-avatar .inprogress {
  background: #5caaf0;
}

.status-avatar .completed {
  background: #27ae60;
}

.status-avatar .blocked {
  background: #eb5757;
}

.status-avatar .delegated {
  background: #034e91;
}

.progress-bar .ui.progress {
  height: 10px;
  background: #e0e0e0;
  margin: 5px 0px 10px 0px;
}

.progress-bar .ui.progress .bar {
  height: 10px;
  background: #9b51e0;
  min-width: 1em;
}

.progress-bar {
  background: #f8f8f8;
  padding: 5px 16px 0px 17px;
  overflow: hidden;
}

.progress-title {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

.progress-percent {
  font-size: 12px;
  float: right;
  color: #201d1d;
}

.my-sessions .each-session.taskList {
  padding: 0px;
}

.my-sessions .each-session.taskList .each-task {
  padding: 10px;
  overflow: hidden;
}

.task-overview .task-details .task-num {
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #828282;
  margin-top: 2em;
}

.task-overview .task-details .task-title {
  font-weight: 600;
  color: #201d1d;
  margin: 10px 0px 20px 0px;
  font-family: "Inter", sans-serif;
  line-height: 26px;
}

.task-overview .task-details .progress-bar {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  border-radius: 4px;
  margin: 20px 0px 30px 0px;
  padding: 15px;
}

.task-overview .task-details .progress-bar .progress-title,
.task-overview .task-details .progress-bar .progress-percent {
  font-size: 14px;
  line-height: 18px;
}

.task-overview h4.ui.header {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-family: "Inter", sans-serif !important;
}

.task-overview .task-details .description p,
.task-overview .assignee,
.task-overview .status-avatar,
.task-overview .sessions,
.task-overview .comments {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.comments-add input {
  height: 40px;
  width: 70%;
  margin-left: 10px;
  padding-left: 15px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
}

.comments-add input.editingText {
  width: 85%;
  margin: 10px 0;
}

.comments-add .collaborators.ui.circular.label {
  margin-top: 5px;
  min-height: 20px;
  min-width: 20px;
}

.comments-add .collaborators.ui.circular.label img {
  height: 20px !important;
}

.comments-add i {
  color: #bdbdbd;
}

.profile-image {
  float: left;
}

.comment-data {
  float: right;
  width: calc(100% - 35px);
}

.comment-data p {
  font-weight: normal;
  line-height: 18px;
  color: #4f4f4f;
}

.each-comment {
  overflow: hidden;
  margin-bottom: 20px;
}

.each-comment .comment-data button {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
}

.each-comment .comment-data button.delete {
  margin-left: 20px;
}

.comment-data .name {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.comment-data .date {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  margin-left: 5px;
}

.marT20 {
  margin-top: 20px;
}

.marT10 {
  margin-top: 10px !important;
}

.task-overview .assignee span {
  margin: 3px 0px 0px 10px;
  display: inline-block;
}

.task-overview .status-avatar,
.task-overview .sessions,
.comments {
  margin: 20px 0px 0px 0px;
}

.task-overview .sessions h4 {
  display: inline-block;
}

.task-overview .sessions .session-number {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 17px;
  float: right;
  padding: 0px 5px;
}

.task-overview .assignee .handOver {
  font-weight: 600;
  line-height: 18px;
  color: #0971ce;
  float: right;
}

.task-overview .assignee .handOver.pending,
.ui.list>.item>.content.pending-request {
  background: #e0e0e0;
  border-radius: 2px;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #828282;
}

.ui.list>.item>.content.pending-request {
  margin-top: 15px;
}

.carousel-card .task-details .task-priority {
  margin-left: 0px;
}

.carousel-card.task-card.ui.card>.content {
  padding: 0px;
}

.carousel-card.task-card .card-wrapper {
  padding: 1em 1em;
}

.task-card .progress-bar {
  margin-top: -11px;
  padding-top: 7px;
}

.progress-bar .input-range {
  margin-top: 10px;
}

.input-range__label-container {
  display: none;
}

.input-range__track.input-range__track--background .input-range__track--active,
.input-range__slider-container .input-range__slider {
  background: #9b51e0;
  border: 1px solid #9b51e0;
}

.progress-bar .input-range__track {
  height: 0.7rem;
}

.input-range__slider-container .input-range__slider {
  width: 1.4rem;
  height: 1.4rem;
  margin-top: -15px;
}

.label-session {
  font-weight: 600;
  color: #bdbdbd;
}

.value-session {
  font-style: normal;
  font-weight: normal;
  color: #4f4f4f;
}

.marB10 {
  margin-bottom: 10px;
}

.task-overview {
  padding: 65px 20px 50px 20px;
}

.decline-modal .task-num {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #b9c3c5;
}

.decline-modal p {
  line-height: 18px;
  color: #4f4f4f;
}

.decline-modal h2.ui.header {
  margin: 0.1em 0 0.8em 0;
}

.decline-modal .ui.form .field>label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}

.decline-modal {
  padding: 1em 1.5em 2em 1.5em;
}

.task-overview .status-avatar.taskDetailsStatus {
  border: none;
}

.decline-modal p.error {
  color: red;
  font-weight: bold;
}

.lexxDeclineReasonModal.ui.modal .content {
  background: #fff6db;
}

.lexxDeclineReasonModal .content p i {
  color: #f8ba00;
}

.task-overview .ui.button.generate-report {
  width: 100%;
  margin: 6px 10px 10px 10px;
  background: #0971ce;
  color: white;
}

.notification-number.session-chat {
  left: inherit;
  top: inherit;
  bottom: 28px;
  right: 42%;
}

.notification-number.session-chat.owner {
  right: 37%;
}

.form-page-wrapper {
  margin-top: 7% !important;
  margin-left: .5rem;
}

.view-feedback {
  min-width: 30%;
  background: #e7f1f563;
  padding-top: 1em;
  margin-left: 1em;
  box-shadow: 0px 0px #f5f5f5, -0.25em 0 0.4em #a1a195;
  width: min-content;
  overflow-y: scroll;
}

.feedback-card {
  min-width: 30%;
  background: #ffffff;
  padding-top: 1em;
  margin-left: 1em;
  border-radius: .35em;
  width: 95%;
  box-shadow: 0px 0px #f5f5f5, -0.25em 0 0.4em #a1a195;
  padding-bottom: 1em;
  margin-bottom: 1rem;
}

.session-summary {
  padding: 0px 20px 0px 20px;
  background: white !important;
  margin-top: 7% !important;
}
.documentlist{
  padding: 0px !important;
  background: white !important;
  margin-top: 39px !important;
}

.document-table {
  width: fit-content;

}
.preview-summary {
  padding: 0px 20px 0px 20px;
  background: white !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 72.9vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6% ;
}
.session-summary h2.ui.header,
.report-summary h2.ui.header {
  margin-bottom: 0px;
  display: inline-block;
}

.session-summary i.alternate {
  float: right;
}

.session-summary .session-details {
  background: #fff;
  padding: 0px 15px;
}

.session-summary .session-details div {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0px;
}

.session-summary .label {
  line-height: 16px;
  color: #828282;
  font-size: 12px;
}

.session-summary .value {
  font-size: 14px;
  line-height: 18px;
  color: #201d1d;
  text-transform: capitalize;
}

.session-summary h3.ui.header {
  margin-top: 2.5em;
}

.session-summary h3.attachments {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

.session-summary ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.session-summary ul li {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  overflow: hidden;
  padding: 15px 0px;
}

.session-summary ul li img {
  float: left;
}

.session-summary ul li span.record {
  padding: 3px 0px 0px 20px;
  line-height: 18px;
  float: left;
}

.session-summary ul li span.arrow {
  float: right;
  font-size: 1.7rem;
}

/* 
.session-summary .menu div,
.report-summary .menu div {
  width: 50%;
  font-size: 13px;
}

.session-summary button.secondarybtn,
.report-summary button.secondarybtn {
  background: #f2f2f2;
  border-radius: 4px;
  border: none;
  color: #828282;
  width: 100%;
}

.session-summary button.primarybtn,
.report-summary button.primarybtn {
  background: #0971ce;
  border-radius: 4px;
  border: none;
  color: #fff;
  width: 100%;
}

.session-summary #three-menu div,
.report-summary #three-menu div {
  width: 33.3%;
  font-size: 13px;
}

.session-summary .sharedUser img {
  width: 16px;
  height: 16px;
  border-radius: 9px;
  vertical-align: bottom;
}

.session-summary .sharedUser {
  background: #f2f2f2;
  border-radius: 20px;
  padding: 5px;
  margin: 0px 5px 5px 0px;
  display: inline-block;
}

.session-summary .task-details,
.report-summary .task-details {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 0.7em;
}

.report-summary .header-container {
  background: #ffffff;
  padding: 75px 20px 0px 20px;
}

.report-summary .body-container {
  padding: 30px 20px 65px 20px;
  background: #f8f8f8;
}

.report-summary .header-container span,
.session-summary .header-container span {
  background: #e0e0e0;
  border-radius: 8px;
  height: 5px;
  display: inline-block;
  width: 23%;
  margin-right: 5px;
  margin-bottom: -5px;
}

.report-summary .header-container span.filled,
.session-summary .header-container span.filled {
  background: #9b51e0;
}

.report-summary .task-details {
  margin-bottom: 0.7em;
}

.report-summary h3.ui.header {
  font-size: 20px;
}

.report-summary input[type="text"] {
  height: 40px;
  width: 85%;
  margin: 10px 0px;
  padding-left: 15px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
}

.each-log .date {
  font-weight: 600;
  font-size: 12px;
  color: #201d1d;
  margin-right: 5px;
}

.each-log .log {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.report-summary-step4 .assignee span {
  margin: 2px 6px;
  display: inline-block;
}

.report-summary-step4 .assignee {
  background: #f2f2f2;
  border-radius: 20px;
  padding: 5px;
  width: max-content;
}

.report-sessions .each-session {
  overflow: hidden;
}

.report-sessions.session-summary {
  padding: 50px 20px 80px 20px;
}

.report-sessions.session-summary h3.ui.header {
  margin-top: 1em;
}

.right-width {
  width: calc(100% - 30px);
}

.session-summary .header-container span.saveDraft {
  float: right;
  width: auto;
  height: auto;
  background: transparent;
  padding: 24px 0px 10px 0;
  color: #0971ce;
  font-weight: 600;
  line-height: 18px;
}

.task-info {
  float: left;
  width: 75%;
}

i.report-generated-icon.icon {
  font-size: 3em;
}

.report-gen-btn {
  margin: 0px 15%;
}

.close-report-wrapper {
  margin-top: -29px;
  background: #f8f8f8;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  border-radius: 0px;
  padding: 10px;
  margin-bottom: 30px;
}

.close-report-wrapper i {
  font-size: 1.5em;
}

.close-report-wrapper span {
  font-weight: 600;
}

.close-report-wrapper button.ui {
  float: right;
  padding: 0.5em;
  margin: 0px 10px;
}

.session-summary .menu div.width100 {
  width: 100%;
}

.ui.segment.green-error {
  background-color: #e0f6e7;
}

.ui.segment.red-error {
  background-color: #eb5757;
}

.ui.segment.red-error p {
  color: #fff;
}

.login-msg.green {
  color: green;
}

.qrcode-scanner button.primary {
  padding: 10px;
  color: white;
  margin: 10px 0px;
  width: 45%;
  float: left;
}

.qrcode-scanner button.secondary {
  padding: 10px;
  color: #0971ce;
  margin: 10px 0px 10px 10px;
  width: 45%;
  float: left;
}

.qrcode-scanner #reader {
  display: inline-block;
}

.qrcode-scanner .wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.qrcode-scanner .wrapper .btn-wrapper {
  display: inline-block;
  width: 100%;
}

.procedure-table table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0px;
}

.procedure-table table td,
.procedure-table table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.procedure-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.procedure-table table tr:first-child {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.procedure-table table tr.no-background {
  background-color: transparent;
}

/* CSS - Kunal */
.session-summary .main-header h2 {
  padding: 20px 20px 5px 25px;
  margin: -20px -25px 3px -25px;
}

.session-summary .main-heading h2 {
  padding: 20px;
  background: #fff;
  margin: -20px -25px 0 -25px;
}

.session-summary .ui.table {
  margin: 10px 0 1em 0;
}

.session-summary .ui.form textarea  {
  resize: none;
  height: 120px;
}
.instruction-input{
  resize: none;
  height: 180px;
  padding-left: 10px;
  
}

.step-feedback-wrap { 
  display: flex;
  flex-direction: row;
}

.input-wrap {
  padding-right: 136px !important;
}

.editorjs{
  background-color: white;
  overflow-y:auto;
  overflow-x:auto;
  border: 0.5px solid rgb(208, 201, 201);
}
grammarly-editor-plugin {
  --grammarly-button-position-left: 1100px;
  --grammarly-button-position-bottom:-114px;
}
.table-editorjs{
  padding-left: 24px;
  height: 104px;
  width: 500px;
  background-color: white;
  overflow-y:auto;
  overflow-x:auto;
  border: 0.5px solid rgb(208, 201, 201);
}
.session-summary .ui.table thead th {
  background: #e8e8e8;
}
.step-card { 
  color: #000000 !important;
  padding-right: 1em;
}
.step-wrapper{
  max-width: 70%;
}
.card-container {
  padding: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}

.dropdown-icon {
  cursor:pointer;
  /* margin-left: auto !important; */
}
.ui.styled.accordion .accordion .title, .ui.styled.accordion .title {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 0.75rem !important;
}
.wrap-icons {
  display: flex;
  margin-left: auto;
  gap: 1rem;
}
.wrap-icons i{
  font-size: 1.2em;
}

.stepbtn {
  background: #0971CE !important;
  color: #FFFFFF !important;
  width: 130px !important;
  line-height: 18px;
  height: auto;  
  max-height: 38px;
  padding: 2px !important;
}

.feedback-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.session-summary .ui.accordion .title:not(.ui) {
  padding: 20px !important;
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  border-radius: 4px;
  margin-bottom: 10px;
  color: #1c2122;
}

.session-summary i.icon {
  color: #8f8f8f;
}

/* .session-summary .ui.accordion .title .chevron.icon {
  float: right;
  transition: all 1s;
  margin-left: 15px;  
} */

.session-summary .ui.accordion .active.title .chevron.icon {
  transform: rotate(180deg);
  margin-top: 5px;
}

.session-summary .ui.accordion .active.content {
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  padding: 25px 17px !important;
  margin-top: -10px;
  margin-bottom: 10px;
}

.session-summary .ui.breadcrumb a {
  color: #bdbdbd;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.session-summary .ui.breadcrumb {
  margin: 20px 0;
}

.session-summary .ui.breadcrumb .active.section {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #201d1d;
}

.session-summary .sub-heading h3 {
  margin: 30px 0 25px 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: .5rem;
  background: #F5F5F5 !important
}

.split {
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
}

/* Control the left side */
.left {
  left: 0;
}

.header-wrap {
  position: fixed !important;
  top: 0 !important;
}

/* Control the right side */
.right {
  right: 0;
}
.mandatory {
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  padding-top: 15%;
  transform: translate(-50%, -50%);
  width: 505px;
}

.buttons {   
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 37px 11px 65px 12px;
  position: initial;
  width: 100%;
  height: 40px;
  left: 969px;
  top: 637px;
  border-radius: 4px;
  justify-content: flex-end;
  margin-top: 3rem;
}

.notification-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 19rem;
}

.savebtn {
  background: #0971CE !important;
  color: #ffffff !important;
  
}
.secondory-btn{
  background: #ffffff !important;
  color: #0971CE !important;
  border: 1px solid #0971CE !important;

  font-size: 12px !important;

}
.notification-list-wrap {
  margin: 0 !important;
}
.read-all-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4.25rem;
  margin-bottom: 0.5rem;
}
.read-all-btn {
  border: 2px solid #0971ce !important;
  color: #0971ce !important;
  background-color: white;
}
.open-document-btn {
  background: #0971CE !important;
  color: #ffffff !important;
 margin-left: 61% !important;
 margin-bottom: 5px !important;
}
.feedback-filter{
  height: 30px !important;
  margin-bottom: 12px !important;

  margin-right: 10px !important;
  
}
.resolvebtn {
  background: #0971CE !important;
  color: #ffffff !important;
  padding: 5px 15px !important;
}

.resolvedbtn {
  background: #27AE60 !important;
  color: #ffffff !important;
  padding: 5px 15px !important;
}

.declinedbtn {
  background: #E74F2E !important;
  color: #ffffff !important;
  padding: 5px 15px !important;
}

.feedback-header{
  margin-left: 1rem;
  font-weight: 600;
  font-size: 16px;
  color: #0971CE;
  margin-left: 1rem;
}
.cancelbtn {
  background: #FFFFFF;
  border: 1px solid #0971CE !important;
}
.declinebtn{
  background: #FFFFFF;
  border: 1px solid #0971CE !important;
  padding: 5px 15px !important;
}
.session-summary .breadcrumb-sub-heading h3 {
  margin: 20px 0 25px 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.session-summary .pushable>.pusher {
  background: #f8f8f8;
}

#penIcon {
  float: right;
  font-size: 18px;
  margin-left: 4px;
}
.status-label {
  text-align: center !important;
}

.table-content {
  max-width: 200px;
}

.session-summary .ui.table td {
  padding: 18px 12px;
}

.session-summary .ui.horizontal.label {
  padding: 2px 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
}

.session-summary .ui.table thead th {
  padding: 10px 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.session-summary .ui.table tbody td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #000000;
  padding: 16px 7px;
}

.session-summary .ui.celled.table tr td,
.session-summary .ui.celled.table tr th {
  border-left: none;
}

.session-summary .ui.accordion .title:not(.ui) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: #fff;
}

.session-summary .ui.accordion .title:not(.ui) img {
  margin-right: 15px;
  height: 14px;
}

.session-summary .menu div.fullwidthmenu {
  width: 100%;
}

.session-summary button.stepbtn {
  background: #e8e8e8;
  border-radius: 4px;
  border: none;
  color: #0971ce;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 19px;  
  margin-top: 0.75rem !important;
}

.session-summary .form-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin: 25px 0 5px 0;
}

.session-summary .ui.form input:not([type]) {
  height: 40px;
}

.session-summary .ui.breadcrumb .icon.divider {
  color: #201d1d;
}

.draft .ui.label {
  background:  #5CAAF0;
  text-align: center !important;
}

.awaiting-review .ui.label {
  background:#F2994A;
}

.review-inprogress .ui.label {
  background:  #0971ce ;
}

.approved .ui.label {
  background: #27AE60;
}

.awaiting-update .ui.label {
  background:#EB5757;
}

.unapproved .ui.label {
  background:#E3E72E;
}
.session-summary .mandatory-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #8F8F8F; 
}

/* Generate report */
.main-heading h2 {
  padding: 20px;
  background: #FFF;
  margin: -20px -25px 0 -25px;
}

.generate-report h3.ui.header {
  margin-top: 1.5em;
}

/* AddSolution */
.add-solution div.breadcrumb {
  padding-top: 20px;
}

.add-solution .ui.breadcrumb .section {
  line-height: 18px;
  color: #c4c4c4;
  font-size: 14px;
}

.add-solution .ui.breadcrumb .active.section {
  line-height: 18px;
  color: #201D1D;
  font-size: 14px;
}

.add-solution h3.ui.header {
  margin: 1.5em 0 0.5em;
}

.asLabel {
  line-height: 1px;
  color: #828282;
  font-size: 13px;
  padding-top: 18px;
}

.add-solution .linkButton,
.linkButton:hover,
.linkButton:focus {
  border: none;
  color: #0971CE;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #8F8F8F;
  margin: 25px 0 5px 0; ;
}

.searchsolution {
 
  padding: 0;
}

.searchsolution .ui.input {
  height: 40px;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #BDBDBD;
 
  
}

.searchsolution .ui[class*="left icon"].input>i.icon {
  color: #1C2122;
}

.searchsolutionstatus {
  padding-left: 10px !important;
  padding-bottom: 5px;
  
}

.ui.grid>.row>.column {
  padding-right: 0px !important;
}

.searchsolutionstatus .ui.selection.dropdown {
  min-width: 160px;
  display: flex;
  justify-content: center;
}

.searchsolutionstatus .ui.selection.dropdown {
  background: #F2F2F2;
  padding: 9px 12px;
  border: none;
  border-radius: 4px;
  color: #828282;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

}

.searchsolutionstatus .ui.dropdown .menu .active.item {
  color: #828282;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
}

.searchsolutionstatus .ui.dropdown>.text {
  color: #828282;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.searchsolutionstatus .ui.dropdown .menu>.item {
  color: #828282;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
}

.searchsolutionstatus .ui.selection.dropdown .menu>.item {
  width: 100%;
}


.fault-details {
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  padding: 12px;
  margin-bottom: 50px;
  position: relative;
}

.fault-details h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.fault-details h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 4px 0 12px 0;
}

.fault-details p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

#faultDetailsPenIcon {
  position: absolute;
  right: 15px;
  top: 18px;
}

#lexxPopUpMenu {
  padding: 0;
  margin: 110px -25px 0 0px;
  border-radius: 4px;
}

#lexxPopUpMenu.ui.left.center.popup:before {
  display: none;
}

#lexxPopUpMenu .ui.menu .item {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1C2122;
  padding: 7px 8px;
}

#lexxPopUpMenu .ui.vertical.menu {
  padding: 8px 0;
  box-shadow: 0px 2px 8px rgba(0, 27, 51, 0.15), 0px 8px 12px rgba(0, 27, 51, 0.15);
}

.session-summary h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.session-summary ul li:first-child {
  border-top: 1px solid #E0E0E0;
}

.hr-line {
  height: 1px;
  width: 100%;
  background: #C4C4C4;
  margin: 30px 0;
}

.paragraph-bg {
  background: #F5F5F5;
  border-radius: 22px;
  padding: 2px 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  width: fit-content;
  color: #4F4F4F;
}

.session-summary p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #201D1D;
}

.myContainer {
  display: flex;
  cursor:pointer;
  margin-top: 5px;
}
.ImagesNew {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}
.take {
  position: relative;
  display: flex;
}
.exclamation-icon{
  color: #034E91 !important;
  padding-left: 4px;
  cursor: pointer,
}
.takefile {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
  opacity: 0;
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  padding: 0.3em 1em;
  display: none;
}
.videoIcon {
  position: absolute;
  top: 40%;
  left: 40%;
}
.videoDuration {
  position: absolute;
  bottom: 5%;
  left: 5%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
}
.session-summary .ui.fluid.accordion h4:not(:first-child){
  margin-top:50px;
}
.modal-alert {
  padding: 20px 18px;
  background: #e3f8eb;
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  border-radius: 4px;
  position: fixed;
  top: 0;
  z-index: 5000;
  width: 30%;
  right: 1rem;
}

.modal-alert h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #27ae60;
}

.category-dropdown {
  border: 1px solid rgba(34,36,38,.15);
  padding-right: 15px;
  appearance: none;
  cursor:pointer;
}
.category-dropdown-color {
  border: 1px solid rgba(34,36,38,.15);
  padding-right: 15px;
  appearance: none;
  color: #8F8F8F !important;
  cursor:pointer;
}

.modal-alert p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #000000;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.back-button-wrap{
  cursor: pointer;
}
.animate {
  animation: FadeAnimation 4s ease-in 0.1s forwards;
}
.toast{
  padding: 20px 18px;
  background: #fffffffb;
  box-shadow: 0px 2px 4px rgba(0, 27, 51, 0.15),
    0px 0px 2px rgba(0, 27, 51, 0.15);
  border-radius: 4px;
  position: fixed;
  top: 0;
  z-index: 5000;
  width: 30%;
  right: 1rem;
}
.PopUpIcon {
  position: absolute;
  left: 18px;
  top: 20px;
  margin-right: 10%;
}
.model-alert-msg{
  margin-left: 30px;
}
.model-alert-msg p{
  margin-top:-10px;
}
.button-upload-option{
  height: 100%;
  width: 100%;
  left: 106px;
  top: 20px;
  border: 1px solid #cbcbcc00;
  border-bottom: #0c0c0c;
  padding: 5px;
}
.modal-upload{
  /* height: 45%;
  width: 45% !important;
  top: 40%;
  border-radius: 12px; */

  position: relative !important;
  width: 600px !important;
  height: 370px !important;
  left: 0px;
  top: 0px;

  background: #FFFFFF !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 25px !important;
}
.modal-upload-ai{
  /* height: 45%;
  width: 45% !important;
  top: 40%;
  border-radius: 12px; */

  position: relative !important;
  width: 800px !important;
  height: 400px !important;
  max-height: 400px;
  left: 0px;
  top: 0px;
  overflow: hidden;
  background: #FFFFFF !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
}

/* .modal-upload h3{
  padding-left: 36%;
   padding-top: 2em;
} */
.modal-upload-delete{
  position: relative !important;
  width: auto !important;
  height: auto !important;
  left: 0px;
  top: 0px;

  background: #FFFFFF !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;
}

.modal-feedback-decline{
  position: relative !important;
  width: auto !important;
  height: auto !important;
  left: 0px;
  top: 150px ;

  background: #FFFFFF !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;

}

.modal-feedback-decline-button{
  font-weight: bolder !important;
    margin-left: 5rem !important;
 
    margin-right: 0.5em !important;
    margin-bottom: 4.4rem !important;
}
.modal-heading {
  display: flex;
  
}
.modal-heading h3:first-child{
  padding-left: 16rem;
  padding-top: 2em;

}
 
.modal-heading button:nth-child(2){
 flex-grow: 8;
 margin-left: 8rem;
 margin-top: 1.8rem;
}
.generate-draft{
  width: 100%;
  margin-top: 2rem;
  margin-left: 2rem;
}
.modal-heading-ai{
  display: flex;
  background-color: #0f64ae;
  color: white;
}
.modal-heading-ai h4:first-child{
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.regenerate-button-div{
  margin-top: 1rem;
  margin-left: 2rem;
}
.regenerate-button{
  border: 2px rgb(112, 31, 193) solid !important; 
  border-radius: 25px !important;
}

.ai-generated-desc-confirm{
  padding: 2rem;
}
.wrap-up-ai-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}
.input-ai{
 margin-left: 2rem;
 border: solid 2px rgb(117, 163, 241) !important ;
 color: black;
 width: 90%;
 height: 80%;
}
.icon-info{
  color: white;
}

.input-ai::placeholder{
  padding-top: 10px ;
  color: rgb(41, 40, 40) !important;
  opacity: 0.5;

}
.keyword-wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wrap-input-ai{
  margin-top: 5px;
  height: 54%;
  overflow-y: scroll;
  
}
.loader-ai{
  width: 100% !important;
  height: 100% !important;
}
.ai-button-disable{
  cursor: not-allowed !important;
  opacity: 0.5 !important;
  pointer-events: none !important;

}
.heading-keyword{
  padding-top: 5px;
  padding-left: 2rem;
  font-size: medium;
}
.confirm-modal-ai{
  border: 2px rgb(132, 132, 245) solid;
  margin: 1rem;
  height: 80%;
  overflow-y: scroll;
}
.confirm-modal-ai-h4{
  padding-left: 2rem;
}

.generate-draft-button{
  width: 90% !important;
}
.modal-heading-ai button:nth-child(3){
  flex-grow: 8;
  margin-left: 42rem;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  color: white;
 }
.modal-heading-delete {
  display: flex;
}
.desc-wrapup{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modal-heading-delete div:first-child{
  font-size:medium;
  margin-left: 2rem;
  margin-top: 1.8em;
}
.modal-feedback-warning{
  font-size: medium;
  margin-left: 2rem;
  margin-top: 1.5rem;
  width: auto !important;
  height: auto !important;
  margin-right: 0.25rem;
}
.modal-heading-delete div:first-child h3{

  
}
.modal-heading-delete div:first-child p{
  font-size:small;
 
}
.modal-heading-delete button:nth-child(2){
  font-weight: bolder;
  margin-left: 5rem;
  margin-bottom: 2.4rem;
 }
 .modal-action-delete{
  display: flex;  
  justify-content: flex-end;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 50px;
 }

 .modal-action-feedback{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
 }

.modal-button-option-del2{
  color: white !important;
  background: #034E91 !important;
  width: 6rem;
}
.modal-button-option-del1{
  color: rgb(0, 0, 0) !important;
  border: 1px solid #0971CE !important;
  border-radius: 4px !important;
  width:6rem ;

}

.feedback-btn-wrap {
display: flex;
justify-content: flex-end;

}

.feedback-wrapper { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 3.5rem;
  margin-bottom: 0.5rem;
}

.back-button-wrap {
  display: flex;
  align-items: center;
  font-size: 1.5rem !important;
  height: 1rem !important;
  color: #034e91; 
  cursor: pointer;
  margin-top: 1rem !important;
}

.feedback-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem !important;
}

.photo {
  height: 50px;
  width: 50px;  
  padding: 3px;
}

.image-display {
  height: 20%;
  width: 20%;
  padding: 5px;
  cursor: pointer;
}

.video-display {
  height: 20%;
  width: 36%;
  padding: 5px;
  cursor: pointer;
}

.modal-capture{
  height: 10%;
  width: 60%;
  border-radius: 12px;
}



.modal-action{
  padding: 3px;
  width:100%;
  height: 100%;
 
}
.modal-radio{
  margin-left: 55px;
    /* margin-top: 25px; */
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    /* gap: 20px; */
    font-weight: 700;
    height: 100%;
}

.modal-radio input[type="radio"]{
  margin-right: 14px;
  font-weight: bold;
  accent-color:#0971CE;
  cursor:pointer;
}

.modal-radio-custom:checked ~ label {
  color: #0971CE;
}

.modal-radio-group {
  padding: 5px;
  display: flex;  
  flex-direction: column;
}


.image-container {
  display: flex;
}

.close-button-position {
  transform: translate(-24%, -36%);
  height: min-content;
  margin-top: 7px;
}

.image-wrap {
  display: flex;
}

.modal-button-option {
  display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px;
    grid-gap: 4px;
    gap: 4px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 85px;
    height: 40px;
    left: 253.74px;
    top: 302.71px;
    color: white !important;
    background: #034E91 !important;
    border-radius: 4px;
}

.modal-height {
  height: 100%;
}

.thankyou-wrapper {
  left: 50%;
  position: absolute;
  margin-left: -14em;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  /* padding: 10px 0px 0px 25px; */
  font-weight: bolder;
  padding-bottom: 10px;
  text-align: center;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d1ecf1;
  border-color: #bee5eb
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.ui .container {
padding-right: 2px;
}


.dashboard-card-buttons{
  margin-left: 70px;
display: flex;
gap: 25px;

}

.count-over-icon{
  height: 15px;
  width: 15px;
  background-color: rgb(209, 43, 43);
  border-radius: 50%;
  display: inline-block;
}

.tableWrap-feedback{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.tableWrap-feedback table{

border: none;
}

.tableWrap-feedback td{
  border: none;
}
.tableWrap-feedback th{
  border: none;
}

.new .ui.label {
  background:#EBD357;
}

.decliened .ui.label {
  background:  #E74F2E;
}

.approved .ui.label {
  background: #27AE60;
}

.modal-feedback{
  position: relative !important;
  left: 0px;
  top: 0px;
  padding: 1.5rem;

  background: #FFFFFF !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;
}

.button-view-feedback{
  width: 98%;
  display: flex;
  justify-content: flex-end;
  /* position: relative; */
  padding-bottom: 1rem;
}
.modal-button-option-viewfeedback{
  color: white !important;
  background: #034E91 !important;
}
.feedback-table-page h1{
  /* margin-left: 2.5rem;
  margin-top: 1.5rem; */
  margin-bottom: revert;
}
.feedback-table-page { 
  margin-top: 4.5%;
}
.modal-heading-feedback{
  display: flex;
  flex-direction: column;
  height: auto!important;
  min-height: 330px;
  padding: 0.5rem;
}
.modal-feedback-heading{
  position: relative;
}
.modal-feedback-heading button{
  position: absolute;
  right: 10px;
  top: 5px;
}
.feedback-view { 
  color: #034E91;
  cursor: pointer;
}
.modal-button-option-decline{
  color: rgb(0, 0, 0) !important;
  border: 1px solid #0971CE !important;
  border-radius: 4px !important;
  width: 100%;
  max-width: 80px;
  display: flex !important;
  justify-content: center;
}
.modal-button-option-gotodoc{
  color: white !important;
  background: #034E91 !important;
}

.feedback-counter {   
  position: absolute;
  top: -2px;
  left: 16px;
  background-color: #27AE60;
  color: white;
  border-radius: 10px;
  padding: 1px 3px;
  font: 8px Verdana;
  font-weight: 600;
  border: 1px solid #27AE60;
}
.action-icon {
  color: #3f7bb7 !important;
}

.action-icon-reorder {
 filter: invert(45%) sepia(40%) saturate(756%) hue-rotate(168deg) brightness(91%) contrast(87%);
}

.popup-wrapper{
  display: inline;
  position: relative;
}
.popup{
  position: absolute;
  left: 0;
  background-color: white;
  width: 200px;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;

}
.popup-link{
  position: absolute;
  width:30%;
  background-color: white;
  padding: 10px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;

}
.popup-link button{
  margin-top: 10px !important;
  background-color: #0971ce;
  width: 80px;
  height: 30px;
  border-radius: 2px;
  color: white;

}

.session-summary  ol li span{
 display: block;
}

.session-summary  ul{
  list-style: inside;
}
.session-summary ul li:first-child {
  border-top: none;
}
.session-summary ul li {
  padding: 0;
  padding-left: 27px;
  border-top: none;
  border-bottom: none;
}
.doc-title {
  margin-left: 30px;
  font-weight: bold;
  font-size: 18px; /* Adjust the size as needed */
  color:#0971CE;
}

.title {
  font-size: 18px; /* Adjust the size as needed */
  font-weight: bold;
}
.doclist-loader{
  position: absolute;
  right: 620px;
  bottom: 100px;
}
.feedbackList-loader{
  position: absolute;
  right: 620px;
  bottom: 200px;
}
.readonly-preview{
  display: none;
  
}
.log-card{
 padding: 10px;
  margin-top: 20vh !important;
  height: 60vh !important;
  overflow-y: scroll;
  width: 80vh !important;
  margin-left:-20px !important;
  background-color: white !important ;
  position: absolute !important;
  right: 20px   !important;
  border: 1px solid #cbcbcc00 !important;
  z-index: 3;
  
  
}
.log-card h2{
  color:#0971ce ;
}

.log-card h4{
  margin-bottom: 2px;
}
ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}
.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 1s forwards !important;
  display: none;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%); 
    
  }
}
.rectangle-container {
  position: relative;
  width: 300px;
  height: 200px;
  border: 2px solid #0971ce;
  margin-left: 18%;
  margin-top: 16%;
}

.outer-rectangle {
  position: absolute;
  top: 29px;
  left: 25px;
  width: 250px;
  height: 150px;
  border: 2px solid #0971ce;
}

.inner-rectangle {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 200px;
  height: 100px;
  border: 2px solid #0971ce;
}

.text-no-change {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px !important;
  font-weight: bold !important;
  color: #0971ce !important;
   white-space: nowrap !important; /* Prevent text from wrapping */

}
.text-no-feedback{
  font-size: 24px;
  font-weight: bold;
  color: #0971ce;
  display: flex;
  justify-content: center;
}
.deletedStep{
  font-weight: bold;
  background-color: #fbb6c2;
  color: #555;
}
.newlyAdded{
  font-weight: bold;
    background-color: #d4fcbc;
    color: #555;
}


.summaryOfDoc{
background-color: #E0E0E0;
margin-top: -8px !important;
}
.tilecard{

  display: flex !important;
min-width: 200px;
   background-color: #FFFFFF; 
}


.icon-container{
  width:45px; /* Adjust according to your image width */
  height:45px; /* Adjust according to your image height */
  border-radius: 50%; /* This makes the background round */
  display: flex;
  justify-content: center;
  align-items: center;
 margin-top: 15%;
  margin-right: 10px;

}

.summaryicon{
  width:2em;
  height: 2em;


}

.gray-status-tiles { background-color: #BDBDBD; }
.yellow-status-tiles { background-color: #F2994A; }
.blue-status-tiles { background-color: #5CAAF0; }
.blue-status-tiles-inprogress { background-color: #0971ce ; }
.green-status-tiles { background-color: #27AE60; }


.card-tile{
  display: flex !important;
  flex-direction: row !important;
 height: 70px !important;
 width: 85vh !important;
 font-size: 7px !important;
}
.selected {
  background-color: #EEF5FC !important; /* Change to desired color */
}
.tile-heading{
 
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
}
.tile-number{
  font-size: 12px;
  font-weight: bolder !important;
 
}
.card-group{
  flex-wrap: nowrap !important;
  margin: 0px !important;
}
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set height to fill the container, adjust as needed */
  width: 300%;
  margin-left: 70%;
  margin-top: 20%;
  color: #034e91;
  font-family: "Inter", sans-serif !important;

}

.No-data {
  text-align: center; /* Center align the content inside */
}
.changeLog-btn {
  background-color: #0971CE !important;
  color: white !important;
  border: 2px solid #0971CE !important;
  font-weight: bolder;
  -moz-animation: pulse 2s infinite;
-webkit-animation: pulse 2s infinite;
animation: pulse 2s infinite;


}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 #F8BA00;
  }
  70% {
      box-shadow: 0 0 0 30px rgba(92, 170, 240, 0);
  }
  100% {
      box-shadow: 0 0 0 70px rgba(92, 170, 240, 0);
  }
}

.nochangeLog-btn{
  background-image: linear-gradient(to right, #0971CE, #5CAAF0) !important;
  color: white !important;

  font-weight: bolder;
}

.document-table .ui.secondary.menu .item{
  font-size: 12px !important;
}
.tableWrap{
  width:94vw;
  overflow-y: scroll;
  
}

@media screen and (min-width: 1200px) {
  .tableWrap{
    height: 38vh;
  }
}
@media screen and (min-width: 1600px) {
  .tableWrap{
    height: 40vh;
  }
}
@media screen and (min-width: 1900px) {
  .tableWrap{
    height: 57vh;
  }
}
.info-button {
  position: relative;
  margin-right: 10px;
  margin-top: 2.2rem !important;
  margin-left: 1rem !important;
  height: fit-content;
}

.popup-info-ai{
  /* border: 2px solid #f4f2f2; */
  padding: 7px 3px;
  width: 600px;
  position: absolute;
  color: white;
  left:20px;
  top: -6px;
  margin-left: -25px;
}

.icon-info{
  font-size: 1em !important;
}
 .fr-toolbar .fr-command.fr-btn img{
 margin: 0px !important;
 width:150px !important;
}
#ai-button-1 img{
  width:150px !important;

}
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 9999; /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}